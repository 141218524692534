import React, { useEffect, useState } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { ExternalLink, Code, Terminal, Server } from 'lucide-react';
import HeroSection from './components/HeroSection';


function App() {
  const projects = [
    {
      title: "La Rhumerie de La Réunion",
      description: "Site web vitrine pour une rhumerie premium à Saint-Denis. Design élégant mettant en valeur l'ambiance et les produits de l'établissement, avec système de réservation en ligne et menu interactif.",
      techStack: ["WordPress", "PHP", "JavaScript", "CSS3", "SEO"],
      image: "/images/rhumerie.png", 
      link: "https://www.larhumerie-reunion.com/"
    },
    {
      title: "Scovie",
      description: "Système de signalisation numérique open-source pour les établissements scolaires. Une solution innovante permettant la diffusion d'informations en temps réel sur des écrans.",
      techStack: ["Python", "Django", "React", "Responsive Design"],
      image: "/images/scovie.png",
      link: "https://github.com/eldertek/scovie"
    },
    {
      title: "DuplicateFinder",
      description: "Application Nextcloud populaire permettant d'identifier et gérer les fichiers en double. Plus de 10,000 utilisateurs actifs et une note de 4.5/5 sur le Nextcloud App Store.",
      techStack: ["PHP", "Vue.js", "MySQL", "REST API"],
      image: "/images/duplicatefinder.png",
      link: "https://github.com/eldertek/duplicatefinder"
    },
    {
      title: "WP Deploy",
      description: "Solution complète pour le déploiement et la gestion multi-sites WordPress, incluant configuration DNS, SSL, et déploiement automatisé via GitHub Pages.",
      techStack: ["Python", "WordPress", "GitHub Actions", "Nginx"],
      image: "/images/wpdeploy.png",
      link: "https://github.com/eldertek/wp-deploy"
    },
    {
      title: "xNBTD",
      description: "Application de gestion pour entreprise de livraison facilitant l'organisation des tournées et des plannings. Optimisation des routes et suivi en temps réel.",
      techStack: ["Python", "Django", "PostgreSQL", "API REST"],
      image: "/images/xnbtd.png",
      link: "https://github.com/eldertek/xnbtd"
    }
  ];

  const skills = [
    {
      category: "Frontend",
      icon: <Code className="w-6 h-6 text-blue-400" />,
      items: [
        "React.js & Next.js",
        "HTML5 & CSS3",
        "JavaScript/TypeScript",
        "Responsive Design",
        "UI/UX Design Principles"
      ]
    },
    {
      category: "Backend",
      icon: <Server className="w-6 h-6 text-purple-400" />,
      items: [
        "Python & Django",
        "PHP & WordPress",
        "Node.js",
        "MySQL/PostgreSQL",
        "API REST/GraphQL"
      ]
    },
    {
      category: "DevOps & Outils",
      icon: <Terminal className="w-6 h-6 text-green-400" />,
      items: [
        "Git & GitHub Actions",
        "Docker & Kubernetes",
        "Linux & Shell Scripting",
        "Nginx & Apache",
        "CI/CD Pipelines"
      ]
    }
  ];

  // Effet de parallaxe et d'animation
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const headers = document.querySelectorAll('.animate-on-scroll');

      headers.forEach(header => {
        const headerTop = header.offsetTop;
        if (scrolled > headerTop - window.innerHeight / 1.5) {
          header.classList.add('opacity-100', 'translate-y-0');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      <nav className="fixed w-full bg-gray-900/80 backdrop-blur-sm z-50 border-b border-gray-800/50">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
            ATL
          </h1>
          <div className="flex gap-8">
            <a href="#projects" className="hover:text-blue-500 transition-colors">Projets</a>
            <a href="#skills" className="hover:text-blue-500 transition-colors">Compétences</a>
            <a href="#contact" className="hover:text-blue-500 transition-colors">Contact</a>
          </div>
        </div>
      </nav>

      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
        <HeroSection />
      </div>

      <section id="projects" className="py-20">
        <div className="container mx-auto px-6">
          <SectionTitle>Projets Phares</SectionTitle>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12">
            {projects.map((project, index) => (
              <ProjectCard key={index} {...project} />
            ))}
          </div>
        </div>
      </section>

      <section id="skills" className="bg-gray-800/50 py-20">
        <div className="container mx-auto px-6">
          <SectionTitle>Expertise Technique</SectionTitle>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-12">
            {skills.map((skill, index) => (
              <SkillCard key={index} {...skill} />
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="py-20">
        <div className="container mx-auto px-6">
          <SectionTitle>Discutons de votre projet</SectionTitle>
          <div className="mt-12 max-w-2xl mx-auto bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
            <p className="text-center text-gray-300 mb-8">
              Une idée en tête ? Je suis prêt à discuter de vos besoins et à voir comment transformer votre vision en réalité.
            </p>
            <div className="flex flex-col items-center gap-4">
              <a
                href="mailto:me@andrelauret.com"
                className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full hover:opacity-90 transition-opacity"
              >
                Me contacter
              </a>
              <span className="text-gray-400">ou</span>
              <div className="flex gap-6">
                <SocialLink
                  href="https://github.com/eldertek"
                  icon={<FaGithub className="w-6 h-6" />}
                />
                <SocialLink
                  href="https://www.linkedin.com/in/andre-lauret"
                  icon={<FaLinkedin className="w-6 h-6" />}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// Composants utilitaires avec effets de survol améliorés
const SocialLink = ({ href, icon }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="p-3 bg-gray-800/50 rounded-full hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-500 transition-all duration-300"
  >
    {icon}
  </a>
);

const SectionTitle = ({ children }) => (
  <h2 className="text-5xl font-bold text-center animate-on-scroll opacity-0 translate-y-10 transition-all duration-1000 mb-16 flex items-center justify-center h-32">
    <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
      {children}
    </span>
  </h2>
);

const ImageModal = ({ isOpen, onClose, image, title }) => {
  if (!isOpen) return null;
  
  return (
    <div 
      className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="relative max-w-5xl w-full">
        <button 
          onClick={onClose}
          className="absolute -top-12 right-0 text-white hover:text-blue-400 transition-colors"
        >
          Fermer
        </button>
        <img 
          src={image} 
          alt={title} 
          className="w-full h-auto rounded-lg shadow-2xl"
          onClick={e => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const ProjectCard = ({ title, description, techStack, image, link }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="group bg-gray-800/30 backdrop-blur-sm rounded-xl overflow-hidden 
        hover:transform hover:scale-[1.02] transition-all duration-300 border border-gray-700/50
        hover:shadow-lg hover:shadow-blue-500/10">
        <div 
          className="relative cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <img 
            src={image} 
            alt={title} 
            className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105" 
          />
          <div className="absolute inset-0 bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center pointer-events-none">
            <span className="text-white font-medium">Cliquez pour agrandir</span>
          </div>
        </div>
        <div className="p-8">
          <h3 className="text-2xl font-bold mb-3 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            {title}
          </h3>
          <p className="text-gray-300 mb-6">{description}</p>
          <div className="flex flex-wrap gap-3 mb-6">
            {techStack.map((tech, index) => (
              <span key={index} 
                className="px-4 py-1.5 bg-gradient-to-r from-blue-500/20 to-purple-500/20 
                rounded-full text-sm font-medium hover:from-blue-500/30 hover:to-purple-500/30 
                transition-colors">
                {tech}
              </span>
            ))}
          </div>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-2 rounded-lg bg-blue-500/20 
            hover:bg-blue-500/30 text-blue-400 hover:text-white transition-all duration-300"
          >
            Explorer le projet <ExternalLink className="ml-2 w-4 h-4" />
          </a>
        </div>
      </div>
      
      <ImageModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        image={image}
        title={title}
      />
    </>
  );
};

const SkillCard = ({ category, icon, items }) => (
  <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 
    hover:border-blue-500/50 transition-colors group">
    <div className="flex items-center gap-4 mb-6">
      <div className="p-3 bg-gray-700/30 rounded-lg group-hover:bg-blue-500/20 transition-colors">
        {icon}
      </div>
      <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
        {category}
      </h3>
    </div>
    <ul className="space-y-3">
      {items.map((item, index) => (
        <li key={index} className="text-gray-300 flex items-center gap-3 
          hover:text-blue-400 transition-colors">
          <span className="w-2 h-2 rounded-full bg-blue-400"></span>
          {item}
        </li>
      ))}
    </ul>
  </div>
);

export default App;