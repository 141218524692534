import React from 'react';
import { ArrowRight, Star, Users, Code2 } from 'lucide-react';

const HeroSection = () => {
  return (
    <div className="min-h-screen relative bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900">
      <div 
        className="absolute inset-0 opacity-[0.15] mix-blend-overlay"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Cfilter id='noise' x='0' y='0'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='1'/%3E%3C/svg%3E")`,
          backgroundRepeat: 'repeat',
        }}
      />
      <div className="container mx-auto px-6 py-24 relative">
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <div className="flex-1 space-y-8">
            <div className="inline-block">
              <div className="flex items-center gap-2 text-emerald-400 bg-emerald-950/30 rounded-full px-4 py-2 mb-6">
                <span className="inline-block w-2 h-2 bg-emerald-400 rounded-full animate-pulse"></span>
                <span>Disponible pour de nouveaux projets</span>
              </div>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold text-white">
              Transformez votre vision en{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-violet-400 to-purple-500">
                réalité numérique
              </span>
            </h1>
            <p className="text-lg text-slate-300 leading-relaxed max-w-2xl">
              Développeur Full Stack avec plus de 3 ans d'expérience dans la création 
              de solutions digitales sur mesure. Je m'engage à transformer vos idées 
              en applications web performantes et évolutives.
            </p>
            <div className="flex flex-wrap gap-6">
              <a 
                href="#contact" 
                className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-600 to-violet-600 hover:from-blue-700 hover:to-violet-700 text-white px-8 py-4 rounded-lg transition-all transform hover:scale-105 shadow-lg shadow-blue-500/25"
              >
                Démarrer un projet
                <ArrowRight className="w-5 h-5" />
              </a>
              <a href="#projects" className="inline-flex items-center gap-2 border border-slate-700 hover:border-slate-500 px-6 py-3 rounded-lg text-white transition-colors hover:bg-slate-800/50">
                Voir mes réalisations
              </a>
            </div>
            <div className="flex flex-wrap gap-8 pt-8">
              <div className="flex items-center gap-3 p-4 bg-slate-800/30 rounded-xl border border-slate-700/50 hover:border-blue-500/50 transition-colors">
                <div className="p-2 bg-amber-400/10 rounded-lg">
                  <Star className="w-5 h-5 text-amber-400" />
                </div>
                <div>
                  <div className="text-2xl font-bold text-white">4.9/5</div>
                  <div className="text-slate-400 text-sm">Satisfaction client</div>
                </div>
              </div>
              <div className="flex items-center gap-3 p-4 bg-slate-800/30 rounded-xl border border-slate-700/50 hover:border-blue-500/50 transition-colors">
                <div className="p-2 bg-blue-400/10 rounded-lg">
                  <Users className="w-5 h-5 text-blue-400" />
                </div>
                <div>
                  <div className="text-2xl font-bold text-white">15+</div>
                  <div className="text-slate-400 text-sm">Clients satisfaits</div>
                </div>
              </div>
              <div className="flex items-center gap-3 p-4 bg-slate-800/30 rounded-xl border border-slate-700/50 hover:border-blue-500/50 transition-colors">
                <div className="p-2 bg-violet-400/10 rounded-lg">
                  <Code2 className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <div className="text-2xl font-bold text-white">50+</div>
                  <div className="text-slate-400 text-sm">Projets livrés</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 relative hidden lg:block">
            <div className="relative w-[450px] h-[450px] mx-auto">
              <div className="absolute inset-0 bg-gradient-to-tr from-blue-500 via-violet-500 to-purple-500 rounded-full opacity-30 blur-3xl"></div>
              <div className="relative h-full w-full rounded-2xl border-4 border-slate-700/50 overflow-hidden shadow-[0_0_40px_rgba(59,130,246,0.2)] transform transition-transform hover:scale-105">
                <img 
                  src="/images/profile.jpg" 
                  alt="André Théo LAURET" 
                  className="relative rounded-2xl object-cover w-full h-full transition-transform hover:scale-110"
                />
              </div>
              <div className="absolute -bottom-4 -right-4 bg-slate-800/80 backdrop-blur-sm rounded-lg p-4 border border-slate-700 shadow-xl">
                <div className="text-sm text-slate-300">Tarif journalier à partir de</div>
                <div className="text-2xl font-bold text-white">150€ / jour</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;